import React from "react";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { InputAdornment } from "@mui/material";

export default function BDatePicker(props) {
  const onChange = (value) => {
    props.onChange(value === null ? null : moment(value).format("YYYY-MM-DD"));
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        {...props}
        slotProps={{
          // The actions will be the same between desktop and mobile
          actionBar: {
            actions: ["clear"]
          },
          toolbar: { hidden: true },
          textField: {
            required: props.required,
            InputProps: {
              endAdornment: <InputAdornment position="end">{!props.hideIcon && <CalendarMonthOutlinedIcon />}</InputAdornment>
            }
          }
        }}
        value={props.value ? moment(props.value).toDate() : null}
        format="MMM d, y"
        closeOnSelect
        onChange={onChange}
      />
    </LocalizationProvider>
  );
}
