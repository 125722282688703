import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import ItemFilter from "../../planner/component/ItemFilter";
import SummaryByDate from "../component/SummaryByDate";
import BakerOrderSummary from "../../baker/components/BakerOrderSummary";
import { STORAGE_BUCKET } from "../../common/constants";
import { equalsIgnoreCase } from "../../common/util";

export default function OrdersView(props) {
  const { data, search } = props;

  const [filter, setFilter] = useState("");
  const [date, setDate] = useState("");

  const [filteredResults, setFilteredResults] = useState([]);

  useEffect(() => {
    let arr = [];
    arr = data.filter((o) => {
      // check filters
      for (const [k, v] of Object.entries(filter)) {
        if (v.value) {
          if (k === "type") {
            if (v.value === "standard" && o.type !== "shop") {
              return false;
            } else if (v.value === "custom" && o.type === "shop") {
              return false;
            }
          } else if (k === "fulfillment" && !equalsIgnoreCase(o.fulfillment.type, v.value)) {
            return false;
          } else if (k === "store") {
            if (o.fulfillment.location) {
              if (!equalsIgnoreCase(o.fulfillment.location.street, v.value)) {
                return false;
              }
            } else if (!o.fulfillment.address?.toLowerCase().startsWith(v.value?.toLowerCase())) {
              // fallback to string address
              return false;
            }
          }
        }
      }
      // passed all filters
      return true;
    });
    setFilteredResults(arr);
  }, [filter]);

  return (
    <Box>
      {search === "upcoming" && (
        <ItemFilter
          config={[
            { name: "type", options: ["custom", "standard"] },
            { name: "fulfillment", options: data.map((o) => o.fulfillment?.type) },
            filter?.["fulfillment"]?.value === "pickup"
              ? {
                  name: "store",
                  options: data.map((o) => o.fulfillment.type === "pickup" && o.fulfillment.location?.street)
                }
              : ""
          ]}
          data={data}
          onChange={(o) => {
            let tmp = { ...filter, ...o };

            // clear out store location filter if fulfillment is not pickup
            if (tmp["store"]?.value) {
              if (tmp["fulfillment"]?.value !== "pickup") {
                delete tmp["store"];
              }
            }
            setFilter(tmp);
          }}
        />
      )}
      {search === "upcoming" && (
        <Box my={2}>
          <SummaryByDate
            date={date}
            data={filteredResults.map((o) => {
              return { date: o.fulfillment.date, amount: o.src === "mkt" ? o.payment.bakerTotal : o.payment.total, quantity: 1 };
            })}
            from={moment().format("YYYYMMDD")}
            to={moment().add(7, "days").format("YYYYMMDD")}
            onChange={(value) => setDate(value !== date ? value : "")}
          />
        </Box>
      )}
      {filteredResults.map((o, idx) => {
        return (
          <Box key={idx} my={2} sx={{ backgroundColor: "#fff", display: date && date !== o.fulfillment.date ? "none" : "block" }}>
            <BakerOrderSummary key={o.id} order={o} filter={filter} showTs={search === "recent"} />
          </Box>
        );
      })}
      {filteredResults.length === 0 && (
        <Box my={2} px={2} pb={2} textAlign={"center"} sx={{ border: "1px solid #DDD" }}>
          <img style={{ width: "250px" }} src={`${STORAGE_BUCKET}/img/icon/search-results.svg`} />
          <Typography variant="subtitle1">Nothing found</Typography>
          <Typography variant="body1" color="textSecondary">
            Please change your search criteria & try again.
          </Typography>
        </Box>
      )}
    </Box>
  );
}
