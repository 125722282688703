import React, { useContext, useEffect, useState } from "react";
import { Alert, Box, Button, Container, LinearProgress, Tab, Tabs, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import bkstApi from "../../api";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import { UserContext } from "../../context/UserContext";
import BDatePicker from "../../common/input/BDatePicker";
import { useLocation } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { getQueryParam, sortByOrderDateTime } from "../../common/util";
import { useNavigate } from "react-router";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import ItemsView from "../../order/screen/ItemsView";
import OrdersView from "../../order/screen/OrdersView";

const FILTERS = ["recent", "upcoming", "canceled", "date"];

export default function Orders(props) {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  let location = useLocation();

  const [search, setSearch] = useState("recent");
  const [view, setView] = useState("order");

  const [fromDt, setFromDt] = useState("");
  const [toDt, setToDt] = useState("");

  const [orders, setOrders] = useState([]);
  const [items, setItems] = useState([]);

  const [loading, setLoading] = useState(true);

  // Check params to identify search criteria
  useEffect(() => {
    // first check filter
    const searchBy = getQueryParam(window.location.search, "search");
    if (FILTERS.includes(searchBy)) {
      if (searchBy === "date") {
        let [from, to] = [getQueryParam(window.location.search, "from"), getQueryParam(window.location.search, "to")];
        if (from && to) {
          runSearch(searchBy, from, to);
        } else {
          setSearch("date");
          setLoading(false);
        }
      } else if (searchBy === "upcoming") {
        const from = moment().format("YYYY-MM-DD");
        const to = moment().add(7, "days").format("YYYY-MM-DD");
        runSearch(searchBy, from, to);
      } else {
        runSearch(searchBy, "", "");
      }
    } else {
      const from = moment().subtract(7, "days").format("YYYY-MM-DD");
      const to = moment().format("YYYY-MM-DD");
      runSearch("recent", from, to);
    }
  }, [location.key]);

  useEffect(() => {
    if (toDt < fromDt) {
      setToDt("");
    }
  }, [fromDt]);

  const getDescription = () => {
    if (search === "recent") {
      return `Orders placed in the last 24 hours`;
    } else if (search === "upcoming") {
      return `Orders due in the upcoming days`;
    } else if (search === "date") {
      return `Orders due within the specified date range`;
    }
  };

  const runDateSearch = (e) => {
    e.preventDefault();
    navigate(`/order?search=date&from=${fromDt}&to=${toDt}`);
  };

  const runSearch = (search, fromDate, toDate) => {
    setLoading(true);
    bkstApi.get(`/my/order?search=${search}&fromDt=${fromDate}&toDt=${toDate}`).then((res) => {
      setSearch(search);
      setFromDt(fromDate);
      setToDt(toDate);

      let arr = [];
      if (search === "recent") {
        arr = res.data.orders;
      } else {
        arr = res.data.orders.sort(sortByOrderDateTime);
      }
      setOrders(arr);

      let itemArray = [];
      if (search === "upcoming") {
        for (const o of arr) {
          //if (o.items) {
          for (let item of o.items) {
            itemArray.push({ ...item, date: o.fulfillment.date });
          }
          // }
        }
      }
      setItems(itemArray);

      setLoading(false);
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    const description = getDescription();

    return (
      <Container maxWidth="sm">
        <Box mt={4}>
          <Typography variant="h2">Orders</Typography>
          <Box my={1} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Tabs value={search} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile sx={{ borderBottom: "1px solid #ECECEC" }}>
              <Tab value="recent" label="Recent" component={RouterLink} to={`?search=recent`} />
              <Tab value="upcoming" label="Upcoming" component={RouterLink} to={`?search=upcoming`} />
              <Tab value="date" label="Date Range" component={RouterLink} to={`?search=date&from=&to=`} />
            </Tabs>
            {search === "upcoming" && (
              <Box>
                <ToggleButtonGroup
                  color="secondary"
                  size="small"
                  value={view}
                  exclusive
                  onChange={(e, v) => {
                    if (v != null) {
                      setView(v);
                    }
                  }}
                >
                  <ToggleButton value="item">
                    <FormatListBulletedOutlinedIcon />
                  </ToggleButton>
                  <ToggleButton size="small" value="order">
                    <ShoppingBagOutlinedIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            )}
          </Box>
          <Box my={2}>
            <Alert severity="info">{description}</Alert>
          </Box>
          {search === "date" && (
            <Box px={2} py={4} sx={{ backgroundColor: "#FEFEFE", borderRadius: "4px" }}>
              <form onSubmit={runDateSearch}>
                <Box align="center" sx={{ display: "flex", justifyContent: "center" }}>
                  <BDatePicker value={fromDt} label="From Date" onChange={(d) => setFromDt(d)} required autoOk hideIcon={true} />
                  <BDatePicker value={toDt} label="To Date" onChange={(d) => setToDt(d)} required autoOk hideIcon={true} />
                  <Button variant="contained" color="primary" type="submit" style={{ height: "56px" }}>
                    <SearchIcon />
                  </Button>
                </Box>
              </form>
            </Box>
          )}
        </Box>
        {view === "order" && <OrdersView data={orders} search={search} />}
        {view === "item" && <ItemsView data={items} search={search} />}
      </Container>
    );
  }
}
