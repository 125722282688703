import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import LineItemV2 from "../../common/component/lineitem/LineItemV2";
import LineItem from "../../common/component/lineitem/LineItem";
import ItemFilter from "../../planner/component/ItemFilter";
import SummaryByDate from "../component/SummaryByDate";
import { STORAGE_BUCKET } from "../../common/constants";
import { arrayContainsIgnoreCase, equalsIgnoreCase, normalizeFlavor } from "../../common/util";

export default function ItemsView(props) {
  const { data, search } = props;

  const [filter, setFilter] = useState("");
  const [date, setDate] = useState("");

  const [filteredResults, setFilteredResults] = useState([]);

  useEffect(() => {
    setFilteredResults([]);

    let arr = [];
    arr = data.filter((o) => {
      // check filters
      for (const [k, v] of Object.entries(filter)) {
        if (v.value) {
          if (k === "type" && !equalsIgnoreCase(o.type, v.value)) {
            return false;
          } else if (k === "frosting" && !equalsIgnoreCase(o.variant?.frosting?.name, v.value)) {
            return false;
          } else if (k === "flavor") {
            const f = normalizeFlavor(o.variant?.flavor);
            if (!equalsIgnoreCase(f?.name, v.value)) {
              return false;
            }
          } else if (k === "filling") {
            const f = normalizeFlavor(o.variant?.flavor);
            if (!equalsIgnoreCase(f?.filling, v.value)) {
              return false;
            }
          } else if (k === "tag" && !arrayContainsIgnoreCase(o.tags, v.value)) {
            return false;
          }
        }
      }
      // passed all filters
      return true;
    });
    setFilteredResults(arr);
  }, [filter]);

  return (
    <Box>
      <ItemFilter
        config={[
          { name: "type", options: data.map((o) => o.type) },
          { name: "frosting", options: data.map((o) => o.variant?.frosting?.name) },
          {
            name: "flavor",
            options: data.map((o) => {
              const f = normalizeFlavor(o.variant?.flavor);
              return f?.name || "";
            })
          },
          {
            name: "filling",
            options: data.map((o) => {
              const f = normalizeFlavor(o.variant?.flavor);
              return f?.filling || "";
            })
          },
          { name: "tag", options: data.reduce((acc, val) => acc.concat(val.tags), []) }
        ]}
        filter={filter}
        onChange={(o) => {
          setFilter({ ...filter, ...o });
        }}
      />
      {filteredResults && (
        <Box my={2}>
          <SummaryByDate
            date={date}
            data={filteredResults.map((o) => {
              return { date: o.date, amount: o.total || o.price, quantity: o.quantity };
            })}
            from={moment().format("YYYYMMDD")}
            to={moment().add(7, "days").format("YYYYMMDD")}
            onChange={(value) => setDate(value !== date ? value : "")}
          />
        </Box>
      )}
      {filteredResults.map((o, idx) => {
        return (
          <Box key={idx} my={2} p={2} sx={{ backgroundColor: "#fff", display: date && date !== o.date ? "none" : "block" }}>
            {o.total ? <LineItemV2 item={o} view="min" /> : <LineItem item={o} view="min" />}
          </Box>
        );
      })}
      {filteredResults.length === 0 && (
        <Box px={2} pb={2} textAlign={"center"} sx={{ border: "1px solid #DDD" }}>
          <img style={{ width: "250px" }} src={`${STORAGE_BUCKET}/img/icon/search-results.svg`} />
          <Typography variant="subtitle1">Nothing found</Typography>
          <Typography variant="body1" color="textSecondary">
            Please change your search criteria & try again.
          </Typography>
        </Box>
      )}
    </Box>
  );
}
